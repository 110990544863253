<template>
  <div @click="$emit('read', data)" class=" hidden  item_wrap" :class="data.reader == 0 ? 'unread' : ''"
    style="border-bottom: calc(1rem / 32) solid var(--bg)">
    <div class="flex flex-center-ver top8">
      <div class="" v-if="data.reader == 0"></div>
      <div class="font16 title">
        {{ data.title }}
      </div>
    </div>

    <div class="font14 top4 sizeColor">
      {{ $format(data.update || data.indate, verConfig.dateFormat || "MM/dd/yyyy HH:mm:ss") }}
    </div>
    <div class="top4 font16">
      <TextBr :text="data.content"></TextBr>
      <!-- {{ data.content }} -->
    </div>
    <div class="top12"></div>
  </div>
</template>

<script>
import TextBr from "@/components/br.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TextBr
  }
};
</script>

<style lang="less" scoped>
@import url("./common.css");

.unread_wrap {
  margin-right: calc(12rem / 32);
  width: calc(20rem / 32);
  height: calc(20rem / 32);
  /* background-color: var(--err); */
  border-radius: 50%;
}

@paddingRight: 20px;

.item_wrap {
  border-radius: 8px;
  position: relative;

  background: #fff;

  .title {
    font-weight: bold;
    // color: #256dcd !important;
    color: var(--sizeColor);
  }
}

// .unread {
//   &::after {
//     content: "";
//     display: block;
//     @size: 6px;
//     width: @size;
//     height: @size;
//     border-radius: 50%;
//     background-color: #f00;
//     position: absolute;
//     right: @paddingRight;
//     top: 50%;
//   }
// }

.sizeColor {
  color: var(--sizeColor);
  opacity: .6;
}
</style>