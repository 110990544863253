<template>
  <div>
    <div class="br_item" v-for="(item, index) in textArr" :key="index">{{ item }} <br v-if="item == ''" /></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textArr: [],
    };
  },
  created() {
    if (this.text) {
      this.textArr = this.text.split("\n");
    }
  },
};
</script>
<style lang="less" scoped>
.br_item {
  // color: var(--theme);
}
</style>